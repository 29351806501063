import { IconButton } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import React, { useEffect } from "react";
import RestaurantApiService from "../../../api_service/restaurant_api_service";
import ItemCategory from "../../../components/item_category/item_category";
import MobileView from "../../../components/mobile_view/mobile_view";
import OrdersTab from "../../../components/orders_tab/orders_tab";
import OrderDetails from "../../../components/order_details/order_details";
import RestaurantEditRestaurant from "../../../components/restaurant_details_modal/restaurant_details_modal";
import TablesTab from "../../../components/tables_tab/tables_tab";
import TabView from "../../../components/tab_view/tab_view";
import useWindowSize from "../../../utils/use_window_size";
import BriefNavBar from "../../../components/restuarant/common/BriefNavBar";
import store from "../../../store/store";
import { notifyIfOrderChanged } from "../../../utils/notifier";
import { textToSpeech } from "../../../utils/helpers";
import ItemContainer from "../../../components/restuarant/home/ItemsContainer";
import RestaurantSelectItemPopup from "../../../components/restaurant_select_item_popup/restaurant_select_item_popup";
import LocalApiService from "../../../api_service/local_api_service";
import * as Styled from "./home.styled";
import SubscriptionBanner from "../../../components/subscription_banner/subscription_banner";
import { showAlert } from "../../../components/message_box/show_alert";
import SelfOrderingBanner from "../../../components/self_ordering_banner/self_ordering_banner";
import RestaurantOrderItemEditPopup from "../../../components/restaurant_order_item_edit_popup/restaurant_order_item_edit_popup";

export default function Home() {
  const itemCategories = useStoreState((state) => state.itemCategories);
  const selectedTab = useStoreState((state) => state.selectedTab);
  const customerInteracted = useStoreState((state) => state.customerInteracted);

  const setCurrentView = useStoreActions((actions) => actions.setCurrentView);

  const setCustomerInteraction = useStoreActions(
    (actions) => actions.setCustomerInteraction
  );
  const isTablePadFullModeActive = useStoreState(
    (state) => state.isTablePadFullModeActive
  );
  const setIsTablePadFullModeActive = useStoreActions(
    (actions) => actions.setIsTablePadFullModeActive
  );
  const restaurant = useStoreState((state) => state.restaurant);

  const { isTab, height } = useWindowSize();

  const selectedItemCategory = useStoreState(
    (state) => state.selectedItemCategory
  );

  useEffect(() => {
    RestaurantApiService().checkSubscription();
    RestaurantApiService().getRestaurantCall();
    RestaurantApiService().getItemCategoriesCall(true);
  }, []); // eslint-disable-line

  const getTablesHelper = async () => {
    let beforeState = store.getState();
    await RestaurantApiService().getTablesCall(true);
    let afterState = store.getState();
    notifyIfOrderChanged(beforeState.tables, afterState.tables);
  };

  const handleTablePadFullToggler = () =>
    setIsTablePadFullModeActive(!isTablePadFullModeActive);

  useEffect(() => {
    getTablesHelper();
    const interval = setInterval(() => {
      getTablesHelper();
    }, 10000);
    return () => clearInterval(interval);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (selectedTab === "tables") {
      getTablesHelper();
    }
  }, [selectedTab]);

  useEffect(() => {
    setCurrentView("CASHIER");
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!customerInteracted && restaurant?.audio_notifier) {
      showAlert({
        head: "Welcome to CeyPay",
        body: "You will receive an audio notifications when new orders are created and updated!",
        actions: [
          {
            name: "Okay",
            action: () => {
              setCustomerInteraction(true);
              textToSpeech("welcome to Cey Pay", true);
            },
          },
        ],
      });
    }
  }, []); //eslint-disable-line

  const handlePrint = async () => {
    const orders = (await RestaurantApiService().getPrintRequestsCall()).data;
    for (const order of orders) {
      if (order.restaurant.print_receipt) {
        const response = await LocalApiService().receiptPrintCall(order);
        if (response?.success) {
          await RestaurantApiService().clearPrintRequestCall(order.id);
        }
      }
    }
  };

  useEffect(() => {
    let printInterval;
    LocalApiService()
      .printerHealthCheck()
      .then((response) => {
        if (response?.success) {
          printInterval = setInterval(() => {
            handlePrint();
          }, 5000);
        }
      });

    return () => {
      if (printInterval) {
        clearInterval(printInterval);
      }
    };
  }, []);

  return (
    <div>
      <SubscriptionBanner />
      <SelfOrderingBanner />
      {isTab ? (
        <MobileView />
      ) : (
        <Styled.MainContainer isTablePadOnly={isTablePadFullModeActive}>
          <Styled.LeftMainContainer isTablePadOnly={isTablePadFullModeActive}>
            <Styled.LeftFirstChildContainer>
              <Styled.LogoContianer>
                <img width={"100%"} src="/images/logo.png" alt="logo" />
              </Styled.LogoContianer>
              <Styled.CategoryContainer height={height - 70}>
                {itemCategories.map((category, index) => (
                  <ItemCategory
                    key={index}
                    category={category}
                    isSelected={
                      selectedItemCategory
                        ? selectedItemCategory.id === category.id
                        : false
                    }
                  />
                ))}
              </Styled.CategoryContainer>
            </Styled.LeftFirstChildContainer>
            <Styled.LeftSecondChildContainer>
              <Styled.CategoryHeader>
                {selectedItemCategory?.name}
              </Styled.CategoryHeader>
              {selectedItemCategory?.items?.length > 0 && (
                <ItemContainer data={selectedItemCategory?.items} />
              )}
            </Styled.LeftSecondChildContainer>
          </Styled.LeftMainContainer>
          <Styled.RightMainContainer isTablePadOnly={isTablePadFullModeActive}>
            <Styled.RightHeaderContainer>
              <BriefNavBar />
            </Styled.RightHeaderContainer>
            <Styled.TablePadContainer height={height}>
              <TabView
                tables={<TablesTab />}
                orders={<OrdersTab />}
                orderDetails={<OrderDetails />}
              />
            </Styled.TablePadContainer>
            {isTablePadFullModeActive ? (
              <Styled.ShrinkICContainer
                width={height}
                onClick={handleTablePadFullToggler}
              >
                <Styled.TextContent>Exit Fullscreen</Styled.TextContent>
                <IconButton>
                  <FullscreenExitIcon />
                </IconButton>
              </Styled.ShrinkICContainer>
            ) : (
              <Styled.ExpandICContainer
                height={height}
                onClick={handleTablePadFullToggler}
              >
                <IconButton>
                  <UnfoldMoreDoubleIcon />
                </IconButton>
              </Styled.ExpandICContainer>
            )}
          </Styled.RightMainContainer>
          <RestaurantEditRestaurant />
          {restaurant && <RestaurantSelectItemPopup />}
          {restaurant && <RestaurantOrderItemEditPopup />}
        </Styled.MainContainer>
      )}
    </div>
  );
}
