import React, { useEffect, useState } from "react";
import useStyles from "./subscriptions_page_styles";
import AppBar from "../../../components/app_bar/app_bar";
import RestaurantApiService from "../../../api_service/restaurant_api_service";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import commaNumber from "comma-number";
import { Download } from "@mui/icons-material";
import RestaurantEditRestaurant from "../../../components/restaurant_details_modal/restaurant_details_modal";
import { getImageUrl } from "../../../utils/helpers";
import Button1 from "../../../components/button_1/button_1";
import SubscriptionCreateModal from "../../../components/subscription_create_modal/subscription_create_modal";
import { SubscriptionStatus } from "../../../utils/constants";

export default function RestaurantSubscriptionsPage() {
  const classes = useStyles();

  const [state, setState] = useState({
    loading: true,
    restaurant: null,
    accountDetails: [],
  });

  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);

  const getSubscription = async () => {
    const response = await RestaurantApiService().getSubscriptions();
    if (response.success) {
      setState({
        ...state,
        loading: false,
        restaurant: response.data.subscriptions,
        accountDetails: response.data.account_details,
      });
    }
  };

  useEffect(() => {
    getSubscription();
  }, []); //eslint-disable-line

  return state.restaurant ? (
    <div className={classes.root}>
      <AppBar />
      <Details
        restaurant={state.restaurant}
        accountDetails={state.accountDetails}
      />
      <div className={classes.main_row}>
        <Button1
          className={classes.add_btn}
          title={"Upload payment receipt"}
          isSelected
          onClick={() => {
            setSubscriptionModalOpen(true);
          }}
        />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 130 }} align="left">
                  Uploaded date
                </TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell style={{ minWidth: 170 }} align="left">
                  Note
                </TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.restaurant?.subscriptions.map((subscription) => (
                <Row key={subscription.id} subscription={subscription} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <RestaurantEditRestaurant />
      <SubscriptionCreateModal
        open={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        onRefresh={getSubscription}
      />
    </div>
  ) : (
    <div />
  );
}

function Row({ subscription }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="order">
          {format(new Date(subscription.createdAt), "yyyy MMM d")}
        </TableCell>
        <TableCell align="left">
          {commaNumber(subscription.amount.toFixed(2))}
        </TableCell>
        <TableCell align="left">{subscription.note}</TableCell>
        <TableCell
          align="center"
          style={{
            fontWeight: "bold",
            color:
              subscription.status === SubscriptionStatus.APPROVED
                ? "green"
                : "blue",
          }}
        >
          {subscription.status}
        </TableCell>
        <TableCell align="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              window.open(getImageUrl(subscription.receipt), "_blank");
            }}
          >
            <Tooltip title="View payment receipt">
              <Download />
            </Tooltip>
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Details({ restaurant, accountDetails }) {
  const classes = useStyles();

  const [state, setState] = useState({
    subscription_fee: 0,
    subscribed_until: "",
  });

  useEffect(() => {
    setState({
      ...state,
      subscription_fee: restaurant.subscription_fee || 0,
      subscribed_until: restaurant.subscribed_until
        ? format(new Date(restaurant.subscribed_until), "yyyy-MM-dd")
        : "",
    });
  }, [restaurant]); //eslint-disable-line

  return (
    <div className={classes.detail_outer}>
      <TextField
        type={"number"}
        label={"Subscription Fee"}
        InputLabelProps={{ shrink: true }}
        value={state.subscription_fee}
        inputProps={{ min: 0 }}
        InputProps={{ readOnly: true }}
      />
      <TextField
        type={"date"}
        label={"Subscribed Until"}
        InputLabelProps={{ shrink: true }}
        value={state.subscribed_until}
        InputProps={{ readOnly: true }}
      />

      <div className={classes.deposit_card}>
        <div>Deposit Account</div>
        <div className={classes.deposit_outer}>
          {accountDetails.map((accountDetail, index) => (
            <div key={index}>{accountDetail}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
