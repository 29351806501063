import React from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import useWindowSize from "../../utils/use_window_size";

export default function SettingsMenu({
  open,
  anchorRef,
  setOpenMenu,
  showAdminPopup,
}) {
  const history = useHistory();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };
  const currentView = useStoreState((state) => state.currentView);
  const setCurrentView = useStoreActions((actions) => actions.setCurrentView);
  const setRequestedView = useStoreActions(
    (actions) => actions.setRequestedView
  );
  // const setSelectedTab = useStoreActions((actions) => actions.setSelectedTab);
  // const setSelectedItemCategory = useStoreActions(
  //   (actions) => actions.setSelectedItemCategory
  // );

  const logout = () => {
    localStorage.removeItem("sn_rest_restaurant");
    localStorage.removeItem("sn_rest_access_token");
    localStorage.removeItem("sn_rest_refresh_token");
    localStorage.removeItem("cey_pay_current_view");
    history.replace("/");
  };

  const { isTab } = useWindowSize();
  const isCashierView = currentView === "CASHIER";
  const isAdminView = currentView === "ADMIN";

  const AdminPanelMenuItem = () => {
    return (
      <MenuItem
        onClick={(event) => {
          if (isCashierView) {
            setRequestedView("ADMIN");
            showAdminPopup();
          } else {
            setCurrentView("ADMIN");
            history.push("/restaurant/admin");
          }
          handleClose(event);
        }}
      >
        Admin Panel
      </MenuItem>
    );
  };

  const SalesPanelMenuItem = () => {
    return (
      !isTab && (
        <MenuItem
          onClick={(event) => {
            if (isCashierView) {
              setRequestedView("SALES");
              showAdminPopup();
            } else {
              setCurrentView("SALES");
              history.replace("/restaurant/sales");
            }
            handleClose(event);
          }}
        >
          Sales Panel
        </MenuItem>
      )
    );
  };

  const SummaryPanelMenuItem = () => {
    return (
      !isTab && (
        <MenuItem
          onClick={(event) => {
            if (isCashierView) {
              setRequestedView("SUMMARY");
              showAdminPopup();
            } else {
              setCurrentView("SUMMARY");
              history.replace("/restaurant/summary");
            }
            handleClose(event);
          }}
        >
          Item Summary Panel
        </MenuItem>
      )
    );
  };

  const PayoutsPanelMenuItem = () => {
    return (
      !isTab && (
        <MenuItem
          onClick={(event) => {
            if (isCashierView) {
              setRequestedView("PAYOUTS");
              showAdminPopup();
            } else {
              setCurrentView("PAYOUTS");
              history.replace("/restaurant/payouts");
            }
            handleClose(event);
          }}
        >
          Payouts Panel
        </MenuItem>
      )
    );
  };

  const SubscriptionsPanelMenuItem = () => {
    return (
      !isTab && (
        <MenuItem
          onClick={(event) => {
            if (isCashierView) {
              setRequestedView("SUBSCRIPTIONS");
              showAdminPopup();
            } else {
              setCurrentView("SUBSCRIPTIONS");
              history.replace("/restaurant/subscriptions");
            }
            handleClose(event);
          }}
        >
          Subscriptions Panel
        </MenuItem>
      )
    );
  };

  const CashierPanelMenuItem = () => {
    return (
      <MenuItem
        onClick={(event) => {
          setCurrentView("CASHIER");
          if (isAdminView) {
            history.replace("/restaurant/home");
          } else {
            history.push("/restaurant/home");
          }
          handleClose(event);
        }}
      >
        Cashier Panel
      </MenuItem>
    );
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{
        zIndex: 5,
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {currentView === "CASHIER" ? (
                  <>
                    <AdminPanelMenuItem />
                    <SalesPanelMenuItem />
                    <SummaryPanelMenuItem />
                    <PayoutsPanelMenuItem />
                    <SubscriptionsPanelMenuItem />
                  </>
                ) : currentView === "ADMIN" ? (
                  <>
                    <CashierPanelMenuItem />
                    <SalesPanelMenuItem />
                    <SummaryPanelMenuItem />
                    <PayoutsPanelMenuItem />
                    <SubscriptionsPanelMenuItem />
                  </>
                ) : currentView === "SALES" ? (
                  <>
                    <CashierPanelMenuItem />
                    <SummaryPanelMenuItem />
                    <PayoutsPanelMenuItem />
                    <SubscriptionsPanelMenuItem />
                    <AdminPanelMenuItem />
                  </>
                ) : currentView === "PAYOUTS" ? (
                  <>
                    <CashierPanelMenuItem />
                    <SalesPanelMenuItem />
                    <SummaryPanelMenuItem />
                    <SubscriptionsPanelMenuItem />
                    <AdminPanelMenuItem />
                  </>
                ) : currentView === "SUBSCRIPTIONS" ? (
                  <>
                    <CashierPanelMenuItem />
                    <SalesPanelMenuItem />
                    <SummaryPanelMenuItem />
                    <PayoutsPanelMenuItem />
                    <AdminPanelMenuItem />
                  </>
                ) : currentView === "SUMMARY" ? (
                  <>
                    <CashierPanelMenuItem />
                    <SalesPanelMenuItem />
                    <PayoutsPanelMenuItem />
                    <SubscriptionsPanelMenuItem />
                    <AdminPanelMenuItem />
                  </>
                ) : (
                  <div />
                )}

                <MenuItem
                  onClick={(event) => {
                    logout();
                    handleClose(event);
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
