import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AdminLoginPage from "../pages/admin_pages/login_page/login_page";
import AdminHomePage from "../pages/admin_pages/home_page/home_page";
import AdminSalesPage from "../pages/admin_pages/sales_page/sales_page";
import ErrorLogPage from "../pages/admin_pages/error_log/error_log_page";
import ConfigPage from "../pages/admin_pages/config_page/config_page";
import AdminPayoutsPage from "../pages/admin_pages/payouts_page/payouts_page";
import AdminSubscriptionDetails from "../pages/admin_pages/subscriptions_page/subscription_details_page";
import AdminSummaryPage from "../pages/admin_pages/summary_page/summary_page";

export default function AdminRouter() {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}`} component={AdminLoginPage} />
        <Route exact path={`${match.path}/home`} component={AdminHomePage} />
        <Route exact path={`${match.path}/sales`} component={AdminSalesPage} />
        <Route
          exact
          path={`${match.path}/summary`}
          component={AdminSummaryPage}
        />
        <Route
          exact
          path={`${match.path}/payouts`}
          component={AdminPayoutsPage}
        />
        <Route
          exact
          path={`${match.path}/subscriptions/:id`}
          component={AdminSubscriptionDetails}
        />
        <Route
          exact
          path={`${match.path}/error_log`}
          component={ErrorLogPage}
        />
        <Route exact path={`${match.path}/config`} component={ConfigPage} />
      </Switch>
    </div>
  );
}
