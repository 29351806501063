import { Button, Card, Collapse, MenuItem, TextField } from "@mui/material";
import { format, min } from "date-fns";
import React, { useEffect, useState } from "react";
import AdminApiService from "../../api_service/admin_api_service";
import useStyles from "./summary_filter_styles";
import useWindowSize from "../../utils/use_window_size";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function SummaryFilter({ isAdmin, onFilterChanged, filter }) {
  const classes = useStyles();
  const { isTab } = useWindowSize();

  const [restaurants, setRestaurants] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const getAllRestaurants = async () => {
    const response = await AdminApiService().getAllRestaurantsCall();
    if (response.success) {
      setRestaurants(response.data);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getAllRestaurants();
    }
  }, []); // eslint-disable-line

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  return (
    <Card className={classes.outer}>
      {isTab && (
        <div className={classes.filter_header} onClick={toggleFilter}>
          Summary Filters
          <Button>{openFilter ? <ExpandLess /> : <ExpandMore />}</Button>
        </div>
      )}
      <Collapse in={openFilter || !isTab} timeout="auto" unmountOnExit>
        <div className={isTab ? classes.col : classes.row}>
          <TextField
            name="from"
            type="date"
            label="From"
            InputLabelProps={{ shrink: true }}
            value={filter.from}
            onChange={(event) => {
              onFilterChanged("from", event.target.value);
            }}
            inputProps={{
              max: format(
                filter.to ? min([new Date(), new Date(filter.to)]) : new Date(),
                "yyyy-MM-dd"
              ),
            }}
            style={{
              minWidth: "150px",
            }}
          />
          <TextField
            name="to"
            type="date"
            label="To"
            InputLabelProps={{ shrink: true }}
            value={filter.to}
            onChange={(event) => {
              onFilterChanged("to", event.target.value);
            }}
            inputProps={{
              min: filter.from,
              max: format(new Date(), "yyyy-MM-dd"),
            }}
            style={{
              minWidth: "150px",
            }}
          />
          {isAdmin && (
            <TextField
              style={{
                minWidth: "180px",
              }}
              select
              value={filter.restaurant_id}
              label="Restaurant"
              onChange={(event) => {
                onFilterChanged("restaurant_id", event.target.value);
              }}
            >
              <MenuItem value={-1}>All Restaurants</MenuItem>
              {restaurants.map((restaurant) => (
                <MenuItem value={restaurant.id} key={restaurant.id}>
                  {restaurant.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            style={{
              minWidth: "100px",
            }}
            select
            value={filter.is_closed}
            label="Order Status"
            onChange={(event) => {
              onFilterChanged("is_closed", event.target.value);
            }}
          >
            <MenuItem value={-1}>Any</MenuItem>
            <MenuItem value={false}>{"Open"}</MenuItem>
            <MenuItem value={true}>{"Closed"}</MenuItem>
          </TextField>
        </div>
      </Collapse>
    </Card>
  );
}
