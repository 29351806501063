import React, { useEffect, useState } from "react";
import useStyles from "./summary_page_styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { addDays, format } from "date-fns";
import AdminApiService from "../../../api_service/admin_api_service";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import SummaryFilter from "../../../components/summary_filter/summary_filter";

export default function AdminSummaryPage() {
  const classes = useStyles();
  const [state, setState] = useState({
    data: [],
  });

  const [filter, setFilter] = useState({
    from: format(new Date(), "yyyy-MM-dd"),
    to: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    restaurant_id: -1,
    is_closed: -1,
  });

  useEffect(() => {
    AdminApiService()
      .getSummaryList({
        from_date: filter.from,
        to_date: filter.to,
        restaurant_id:
          filter.restaurant_id === -1 ? null : filter.restaurant_id,
        is_closed: filter.is_closed === -1 ? null : filter.is_closed,
      })
      .then((response) => {
        if (response.success) {
          setState({
            ...state,
            data: response.data.data,
          });
        }
      });
  }, [filter]); // eslint-disable-line

  const onFilterChanged = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div className={classes.root}>
      <AdminAppBar title={"Summary"} />
      <SummaryFilter
        isAdmin
        onFilterChanged={onFilterChanged}
        filter={filter}
      />
      <div className={classes.main_row}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 90 }} align="left">
                  Item Name
                </TableCell>
                <TableCell style={{ minWidth: 170 }} align="center">
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.data.map((item) => (
                <Row key={item.id} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function Row({ item }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">{item.name}</TableCell>
        <TableCell align="center">{item.quantity}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}
