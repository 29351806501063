import React, { useState, useRef } from "react";
import { Avatar, IconButton } from "@mui/material";
import { SettingsOutlined } from "@mui/icons-material";

import { useStoreState, useStoreActions } from "easy-peasy";
import useStyles from "../../../app_bar/app_bar_styles";
import SettingsMenu from "../../../app_bar/settings_menu";
import AdminPopup from "../../../app_bar/admin_popup";

import * as Styled from "./index.styled";
import { getImageUrl } from "../../../../utils/helpers";

function Index() {
  const classes = useStyles();
  const restaurant = useStoreState((state) => state.restaurant);
  const setOpenRestaurantDetailsModal = useStoreActions(
    (actions) => actions.setOpenRestaurantDetailsModal
  );

  const [openMenu, setOpenMenu] = useState(false);
  const [openAdminPopup, setOpenAdminPopup] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const showAdminPopup = () => {
    setOpenAdminPopup(true);
  };

  const openRestaurantDetailsModal = () => {
    setOpenRestaurantDetailsModal(true);
  };

  return (
    <Styled.MainContainer>
      <Styled.AvatarContainer onClick={openRestaurantDetailsModal}>
        {restaurant?.logo && <Avatar src={getImageUrl(restaurant?.logo)} />}
      </Styled.AvatarContainer>
      <Styled.RestNameContainer onClick={openRestaurantDetailsModal}>
        {restaurant?.name}
      </Styled.RestNameContainer>
      <Styled.ControlContainer>
        <IconButton
          ref={anchorRef}
          onClick={handleToggle}
          className={classes.setting_button}
        >
          <SettingsOutlined className={classes.setting_icon} />
        </IconButton>
        <SettingsMenu
          open={openMenu}
          anchorRef={anchorRef}
          setOpenMenu={setOpenMenu}
          showAdminPopup={showAdminPopup}
        />
        <AdminPopup open={openAdminPopup} setOpen={setOpenAdminPopup} />
      </Styled.ControlContainer>
    </Styled.MainContainer>
  );
}

export default Index;
