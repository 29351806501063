import React, { useEffect, useState } from "react";
import useStyles from "./sales_page_styles";
import {
  Card,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import { addDays, format } from "date-fns";
import AdminApiService from "../../../api_service/admin_api_service";
import AdminAppBar from "../../../components/admin_app_bar/app_bar";
import commaNumber from "comma-number";
import SalesFilter from "../../../components/sales_filter/sales_filter";
import { PaymentMethods, PaymentModes } from "../../../utils/constants";
import useWindowSize from "../../../utils/use_window_size";

export default function AdminSalesPage() {
  const classes = useStyles();
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalPages: 1,
    totalSales: 0,
    totalTip: 0,
  });

  const [filter, setFilter] = useState({
    from: format(new Date(), "yyyy-MM-dd"),
    to: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    restaurant_id: -1,
    is_closed: -1,
    payment_status: -1,
    currency: "LKR",
    payment_method: -1,
    transaction_method: -1,
    payment_mode: PaymentModes.PRODUCTION,
  });

  useEffect(() => {
    AdminApiService()
      .getOrdersList(
        {
          from_date: filter.from,
          to_date: filter.to,
          restaurant_id:
            filter.restaurant_id === -1 ? null : filter.restaurant_id,
          is_closed: filter.is_closed === -1 ? null : filter.is_closed,
          payment_status:
            filter.payment_status === -1 ? null : filter.payment_status,
          currency: filter.currency,
          payment_method:
            filter.payment_method === -1 ? null : filter.payment_method,
          transaction_method:
            filter.transaction_method === -1 ? null : filter.transaction_method,
          payment_mode: filter.payment_mode === -1 ? null : filter.payment_mode,
        },
        state.page,
        state.limit
      )
      .then((response) => {
        if (response.success) {
          setState({
            ...state,
            data: response.data.data,
            totalPages: response.data.total_pages,
            totalSales: response.data.total_sales,
            totalTip: response.data.total_tip,
          });
        }
      });
  }, [state.page, state.limit, filter]); // eslint-disable-line

  const onFilterChanged = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div className={classes.root}>
      <AdminAppBar title={"Sales"} />
      <SalesFilter isAdmin onFilterChanged={onFilterChanged} filter={filter} />
      <Totals
        sale={state.totalSales}
        tip={state.totalTip}
        currency={filter.currency}
      />
      <div className={classes.main_row}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ minWidth: 90 }} align="center">
                  Order Id
                </TableCell>
                <TableCell style={{ minWidth: 170 }} align="left">
                  Date
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="left">
                  Customer
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="left">
                  Restaurant
                </TableCell>
                <TableCell align="center">Table</TableCell>
                <TableCell style={{ minWidth: 120 }} align="right">
                  Bill Amount
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="right">
                  Tip Amount
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="right">
                  Total Amount
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="center">
                  Order Status
                </TableCell>
                <TableCell style={{ minWidth: 140 }} align="center">
                  Payment Status
                </TableCell>
                <TableCell style={{ minWidth: 150 }} align="center">
                  Payment Method
                </TableCell>
                <TableCell style={{ minWidth: 170 }} align="center">
                  Transaction Method
                </TableCell>
                <TableCell style={{ minWidth: 170 }} align="center">
                  Payment Reference
                </TableCell>
                <TableCell style={{ minWidth: 150 }} align="center">
                  Payment Mode
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.data.map((order) => (
                <Row key={order.id} order={order} />
              ))}
            </TableBody>
          </Table>
          <div className={classes.paginator_outer}>
            {state.totalPages > 1 && (
              <Pagination
                count={state.totalPages}
                page={state.page}
                onChange={(event, newPage) => {
                  setState({ ...state, page: newPage });
                }}
              />
            )}
          </div>
        </TableContainer>
      </div>
    </div>
  );
}

function Row({ order }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand order"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{order.id}</TableCell>
        <TableCell component="th" scope="order">
          {format(new Date(order.createdAt), "yyyy-MM-dd hh:mm aaa")}
        </TableCell>
        <TableCell align="left">
          {order.customer ? order.customer.name : order.customer_name}
        </TableCell>
        <TableCell align="left">{order.restaurant.name}</TableCell>
        <TableCell align="center">{order.table.number}</TableCell>
        <TableCell align="right">
          {commaNumber(order.order_amount.toFixed(2))}
        </TableCell>
        <TableCell align="right">
          {!!order.tip_amount
            ? commaNumber(order.tip_amount.toFixed(2))
            : "----"}
        </TableCell>
        <TableCell align="right">
          {commaNumber((order.order_amount + order.tip_amount).toFixed(2))}
        </TableCell>
        <TableCell align="center">
          {order.is_closed ? "Closed" : "Open"}
        </TableCell>
        <TableCell align="center">{order.payment_status}</TableCell>
        <TableCell align="center">{order.payment_method}</TableCell>
        <TableCell align="center">{order.transaction_method}</TableCell>
        <TableCell align="center">
          {order.payment_method === PaymentMethods.PAYHERE
            ? order.payment_order_id
            : "----"}
        </TableCell>
        <TableCell align="center">{order.payment_mode}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Order Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Total Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.order_items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="order">
                        {item.item.name}
                      </TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="right">{item.unit_price}</TableCell>
                      <TableCell align="right">
                        {(item.quantity * item.unit_price).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {order.order_discounts.length > 0 && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: 500,
                        }}
                        colSpan={4}
                      >
                        Discounts
                      </TableCell>
                    </TableRow>
                  )}
                  {order.order_discounts.map((discount) => (
                    <TableRow key={discount.id}>
                      <TableCell component="th" scope="order">
                        {discount.charge.name}
                      </TableCell>
                      <TableCell>{""}</TableCell>
                      <TableCell align="right">{`${discount.value} ${
                        discount.value_type === "PERCENTAGE" ? "%" : ""
                      }`}</TableCell>
                      <TableCell align="right">
                        {(discount.calculated_value * -1).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {order.order_charges.length > 0 && (
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: 500,
                        }}
                        colSpan={4}
                      >
                        Other Charges
                      </TableCell>
                    </TableRow>
                  )}
                  {order.order_charges.map((charge) => (
                    <TableRow key={charge.id}>
                      <TableCell component="th" scope="order">
                        {charge.charge.name}
                      </TableCell>
                      <TableCell>{""}</TableCell>
                      <TableCell align="right">{`${charge.value} ${
                        charge.value_type === "PERCENTAGE" ? "%" : ""
                      }`}</TableCell>
                      <TableCell align="right">
                        {charge.calculated_value.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {!!order.tip_amount && (
                    <TableRow>
                      <TableCell component="th" scope="order">
                        Tip amount
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {commaNumber(order.tip_amount.toFixed(2))}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="order"
                      style={{ fontWeight: "bold" }}
                    >
                      Total amount
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      {commaNumber(
                        (order.order_amount + order.tip_amount).toFixed(2)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Totals({ sale, currency, tip }) {
  const { isTab } = useWindowSize();
  return (
    <Card
      style={{
        marginTop: "10px",
        padding: "10px",
        textAlign: "center",
        display: "flex",
        flexDirection: isTab ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          marginRight: "20px",
          fontSize: "20px",
          fontWeight: "bold",
          color: "#515051",
        }}
      >
        {`Total Sales (${currency})`}
      </span>
      <span
        style={{
          color: "green",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        {commaNumber(sale.toFixed(2))}
        <span
          style={{
            color: "green",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          {` + ${commaNumber(tip.toFixed(2))} in tips`}
        </span>
      </span>
    </Card>
  );
}
