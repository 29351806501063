import React, { useEffect, useState } from "react";
import useStyles from "./summary_page_styles";
import AppBar from "../../../components/app_bar/app_bar";
import RestaurantApiService from "../../../api_service/restaurant_api_service";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { addDays, format } from "date-fns";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import RestaurantEditRestaurant from "../../../components/restaurant_details_modal/restaurant_details_modal";
import SummaryFilter from "../../../components/summary_filter/summary_filter";

export default function SummaryPage() {
  const classes = useStyles();
  const history = useHistory();
  const restaurant = useStoreState((state) => state.restaurant);
  const isSummaryView = useStoreState(
    (state) => state.currentView === "SUMMARY"
  );
  const [state, setState] = useState({
    data: [],
  });

  const [filter, setFilter] = useState({
    from: format(new Date(), "yyyy-MM-dd"),
    to: format(addDays(new Date(), 1), "yyyy-MM-dd"),
    is_closed: -1,
  });

  useEffect(() => {
    RestaurantApiService()
      .getSummaryCall({
        from_date: filter.from,
        to_date: filter.to,
        is_closed: filter.is_closed === -1 ? null : filter.is_closed,
      })
      .then((response) => {
        if (response.success) {
          setState({
            ...state,
            data: response.data.data,
          });
        }
      });
  }, [filter]); // eslint-disable-line

  useEffect(() => {
    if (!isSummaryView) {
      history.replace("/restaurant/home");
    }
    RestaurantApiService().getRestaurantCall();
  }, []); // eslint-disable-line

  const onFilterChanged = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  return restaurant ? (
    <div className={classes.root}>
      <AppBar />
      <SummaryFilter onFilterChanged={onFilterChanged} filter={filter} />
      <div className={classes.main_row}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 100 }} align="left">
                  Item Name
                </TableCell>
                <TableCell style={{ minWidth: 170 }} align="center">
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.data.map((item) => (
                <Row key={item.id} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <RestaurantEditRestaurant />
    </div>
  ) : (
    <div />
  );
}

function Row({ item }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">{item.name}</TableCell>
        <TableCell align="center">{item.quantity}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}
