import { cdn_url } from "../config/config";
import store from "../store/store";

export const getPaymentStatus = (status) => {
  switch (status) {
    case "NONE":
    case "UNSUCCESS":
    case "CANCELLED":
    case "FAILED":
      return {
        label: "Pay",
        allow_to_pay: true,
      };
    case "SUCCESS":
      return {
        label: "Payment Success",
        allow_to_pay: false,
      };
    case "PENDING":
      return {
        label: "Payment Pending",
        allow_to_pay: false,
      };
    case "INITIALIZED":
      return {
        label: "Payment Initialized",
        allow_to_pay: true,
      };
    default:
      return {
        label: "",
        allow_to_pay: false,
      };
  }
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const getInitialsByName = (name) => {
  if (!name) {
    return "";
  } else {
    return name
      .split(" ")
      .map((w) => w[0])
      .slice(0, 2)
      .join("");
  }
};

export const getFirstTwoNamesFromName = (name) => {
  if (!name) {
    return "";
  } else {
    return name.split(" ").slice(0, 2).join(" ");
  }
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const playAudio = async () => {
  try {
    const audio = new Audio("/audios/bell.wav");
    await audio.play();
  } catch (error) {}
};

export const playAudioCustomer = async () => {
  try {
    const audio = new Audio("/audios/customer_bell.wav");
    await audio.play();
  } catch (error) {}
};

export const textToSpeech = (text, speechOnlyFlag = false) => {
  const restaurant = store.getState().restaurant;
  if (restaurant?.audio_notifier === false) {
    return;
  }
  if (window.flutter_inappwebview?.callHandler) {
    window.flutter_inappwebview.callHandler("textToSpeech", text);
    return;
  }
  if (!speechOnlyFlag) {
    playAudio();
  }
  setTimeout(
    () => {
      SpeechOnly(text);
    },
    !speechOnlyFlag ? 2000 : 0
  );
};

const SpeechOnly = (text) => {
  let speech = new SpeechSynthesisUtterance();
  speech.text = text;
  window.speechSynthesis.speak(speech);
};

export const getTableColor = (table) => {
  let allItemsServed = true;
  let isPaymentRequested = false;
  table?.orders?.forEach((order) => {
    if (order.is_payment_requested) {
      isPaymentRequested = true;
    }
    order.order_items.forEach((item) => {
      if (!item.served) {
        allItemsServed = false;
      }
    });
  });
  return isPaymentRequested
    ? "#b3eeb3"
    : table.orders && table.orders.length > 0 && table.updated
    ? "orange"
    : table.orders && table.orders.length > 0 && allItemsServed
    ? "#fcc0ff"
    : table.orders && table.orders.length > 0
    ? "#f96d3c4a"
    : "white";
};

export const getImageUrl = (url) => {
  if (!url) {
    return url;
  }
  if (url.startsWith("http")) {
    return url;
  } else {
    return `${cdn_url}/${url}`;
  }
};

export const onEnter = (e, callback) => {
  if (e.key === "Enter") {
    callback();
  }
};
